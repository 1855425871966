<template>
  <component :is="profileData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="profileData === undefined">
      <h4 class="alert-heading">Error fetching profile data</h4>
      <div class="alert-body">
        No profile found with this profile id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-profile-list' }"> Profile List </b-link>
        for other profiles.
      </div>
    </b-alert>

    <h3>
      {{ profileData.student ? profileData.student.fullName : 'unknown' }} <small>#{{ profileData.student ? profileData.student.studentId : '0000' }}</small>
    </h3>

    <hr />

    <b-tabs v-if="profileData" pills lazy>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="PaperclipIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Attributes</span>
        </template>
        <profile-edit-tab-attributes :profile-data.sync="profileData" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BCol } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import rosterStoreModule from '../rosterStoreModule';
import ProfileEditTabAttributes from './tabs/ProfileEditTabAttributes';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BCol,

    ProfileEditTabAttributes,
  },
  setup() {
    const profileData = ref(null);

    const PROFILE_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, rosterStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
    });

    store
      .dispatch('app-roster/fetchProfile', {
        id: router.currentRoute.params.id,
        queryParams: {
          populate: 'representative student',
        },
      })
      .then((response) => {
        profileData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          profileData.value = undefined;
        }
      });

    return {
      profileData,
    };
  },
};
</script>

<style></style>
