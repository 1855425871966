<template>
  <div>
    <!-- Header: Personal Info -->

    <!-- Form: Personal Info Form -->
    <b-form>
      <b-form>
        <div class="d-flex">
          <feather-icon icon="ProfileIcon" size="19" />
          <h4 class="mb-0">Session Information</h4>
        </div>

        <!-- Form: Add Inf -->
        <b-row class="mt-1">
          <!-- Field: Attributes - Funding Source -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Funding Source" label-for="profile-attr-funding-source">
              <v-select
                id="profile-attr-funding-source"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.attributes.fundingSource"
                :options="fundingSourceOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.fundingSource = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Attributes - Payment Status -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Payment Status" label-for="profile-attr-payment-status">
              <v-select
                id="profile-attr-payment-status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.attributes.paymentStatus"
                :options="paymentStatusOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.paymentStatus = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Attributes - Submitted to VA -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Submitted to VA" label-for="profile-attr-submitted-to-va">
              <v-select
                id="profile-attr-submitted-to-va"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.attributes.submittedToVa"
                :options="submittedToVaOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.submittedToVa = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: coe uploaded -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Uploaded COE" label-for="profile-attr-u-coe">
              <v-select
                id="profile-attr-u-coe"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.attributes.uploadedCoe"
                :options="uploadedCoeOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.uploadedCoe = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: resume uploaded -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Uploaded Resume" label-for="profile-attr-u-resume">
              <v-select
                id="profile-attr-u-resume"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.attributes.uploadedResume"
                :options="uploadedResumeOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.uploadedResume = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: location -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Location" label-for="profile-attr-u-location">
              <v-select
                id="profile-attr-u-location"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.attributes.location"
                :options="locationOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.location = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Representative -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Representative" label-for="profile-representative">
              <vue-autosuggest
                id="profile-representative"
                ref="autocomplete"
                v-model="representativeQuery"
                :suggestions="representativeSuggestions"
                :input-props="representativeInputProps"
                :section-configs="representativeSectionConfigs"
                :render-suggestion="representativeRenderSuggestion"
                :get-suggestion-value="representativeGetSuggestionValue"
                @input="representativeFetchResults"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Pathway -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Preferred Pathway" label-for="profile-pathway">
              <v-select
                id="profile-pathway"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Preferred Pathway"
                :value="profileDataLocal.pathway"
                :options="pathwaySuggestions"
                class="w-100"
                :reduce="(val) => val.value"
                :clearable="true"
                @search="searchForPathway"
                @input="(val) => (profileDataLocal.pathway = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Subject -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Preferred Subject" label-for="profile-subject">
              <v-select
                id="profile-subject"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Preferred Subject"
                :value="profileDataLocal.subject"
                :options="subjectSuggestions"
                class="w-100"
                :reduce="(val) => val.value"
                :clearable="true"
                @search="searchForSubject"
                @input="(val) => (profileDataLocal.subject = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Pathway Date -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Preferred Start Date" label-for="pathwayDate">
              <b-form-datepicker id="pathwayDate" v-model="profileDataLocal.pathwayDate" class="mb-1" />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Status" label-for="profile-status">
              <v-select
                id="profile-status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.status"
                :options="statusOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.status = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Profile Status Reason -->
          <b-col v-if="shouldProvideReason" offset-lg="5" cols="12" lg="5" md="6" xs="12">
            <b-form-group label="Status Reason" label-for="profile-status-reason">
              <b-form-input
                id="profile-status-reason"
                v-model="profileDataLocal.statusReason"
                class="w-100"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Profile Status Update Date -->
          <b-col v-if="shouldProvideReason" cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Status Date" label-for="profile-status-date">
              <b-form-datepicker id="profile-status-date" v-model="profileDataLocal.statusDate" class="mb-1" />
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex" v-if="enableCourseAttributes">
          <feather-icon icon="ProfileIcon" size="19" />
          <h4 class="mb-0">Course Information</h4>
        </div>

        <b-row class="mt-1" v-if="enableCourseAttributes">
          <!-- Field: Exam Voucher -->
          <b-col cols="12" lg="2" md="2" xs="12">
            <b-form-group label="Course Exam Voucher" label-for="profile-course-exam-voucher">
              <v-select
                id="profile-course-exam-voucher"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.courses[this.courseId].examVoucher"
                :options="examVoucherOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.courses[this.courseId].examVoucher = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Exam Taken -->
          <b-col cols="12" lg="2" md="2" xs="12">
            <b-form-group label="Course Exam Taken" label-for="profile-course-exam-taken">
              <v-select
                id="profile-course-exam-taken"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.courses[this.courseId].examTaken"
                :options="yesNoOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.courses[this.courseId].examTaken = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Exam Passed -->
          <b-col cols="12" lg="2" md="2" xs="12">
            <b-form-group label="Course Exam Passed" label-for="profile-course-exam">
              <v-select
                id="profile-course-exam"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.courses[this.courseId].examPassed"
                :options="yesNoOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.courses[this.courseId].examPassed = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Lab Completed -->
          <b-col cols="12" lg="3" md="3" xs="12">
            <b-form-group label="Course Lab Completed" label-for="profile-course-lab">
              <v-select
                id="profile-course-lab"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.courses[this.courseId].labCompleted"
                :options="yesNoOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.courses[this.courseId].labCompleted = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" lg="3" md="3" xs="12">
            <b-form-group label="Course Status" label-for="profile-course-status">
              <v-select
                id="profile-course-status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="profileDataLocal.courses[this.courseId].status"
                :options="courseStatusOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.courses[this.courseId].status = val)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useProfilesList from '../../profile-list/useProfilesList';
import rosterStoreModule from '../../rosterStoreModule';
import userStoreModule from '../../../user/userStoreModule';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BButton,
    ToastificationContent,
    VueAutosuggest,
  },
  props: {
    profileData: {
      type: Object,
      required: true
    }
  },
  watch: {
    profileData: function() {
    }
  },
  computed: {
    shouldProvideReason() {
      return this.profileDataLocal.statusReason || ["withdrew_s1", "withdrew_s2", "withdrew_s3", "terminated"].includes(this.profileDataLocal.status)
    }
  },
  data: () => {
    return {
      submit: {
        sync: false
      },
      attributes: {
        relocatable: "no",
        remote: "no",
        clearance: "none",
        location: "virtual",
        veteran: true,
      },
      profileDataLocal: { attributes: {} },

      courseId: undefined,
      enableCourseAttributes: false,
    };
  },
  methods: {
    async doSelectRepresentative() {
      if (!this.representativeSelected) {
        return;
      }

      this.$store
        .dispatch('app-placement/addStudentsToCohort', {
          cohortId: this.cohortData.id,
          studentIds: [this.representativeSelected.id],
        })
        .then((response) => {
          console.log(`updated course`, response);

          console.log(response.data);

          this.$emit('refresh');

          this.representativeQuery = undefined;
          this.representativeFetchResults();
        })
        .catch((e) => {
          console.error(`failed to update course`, e);
        });
    },
    representativeFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const usersPromise = this.$store.dispatch('app-user/fetchUsers', {
          search: this.representativeQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.representativeSuggestions = [];

          const users = values[0].data.results;

          users.length && this.representativeSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    representativeRenderSuggestion(row) {
      return `${row.item.name}`;
    },
    representativeGetSuggestionValue(row) {
      if (row.item) {
        return this.representativeRenderSuggestion(row);
      }
      return `${row.name}`;
    },

    pathwayRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    pathwayGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForPathway(search, loading) {
      const response = await this.$store.dispatch('app-roster/fetchPathways', { search, limit: 100 });
      const pathways = response.data.results;

      this.pathwaySuggestions = pathways.map((p) => {
        let { id, name } = p;
        if (!name.includes('Pathway')) {
          name += ' Pathway';
        }
        return { value: id, label: name };
      });
    },
    subjectRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    subjectGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForSubject(search, loading) {
      const response = await this.$store.dispatch('app-roster/fetchSubjects', { search, limit: 100 });
      const subjects = response.data.results;

      this.subjectSuggestions = subjects.map((p) => {
        let { id, name } = p;
        return { value: id, label: name };
      });
    },

    doSaveChanges() {
      const id = this.profileData.id;

      const pl = {
        attributes: this.profileDataLocal.attributes,
        status: this.profileDataLocal.status,
        statusReason: this.profileDataLocal.statusReason,
        statusDate: this.profileDataLocal.statusDate,
        pathway: this.profileDataLocal.pathway,
        pathwayDate: this.profileDataLocal.pathwayDate,
        subject: this.profileDataLocal.subject,
        representative: this.profileDataLocal.representative,
        courses: this.profileDataLocal.courses
      };

      if (this.representativeSelected) {
        pl.representative = this.representativeSelected.id;
      }

      store
        .dispatch('app-roster/updateProfile', { id, profileData: pl })
        .then((response) => {
          this.profileDataLocal = response.data;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    loadProfile() {
      this.profileDataLocal = { ...this.profileData };
      this.searchForPathway(``);
      this.searchForSubject(``);

      this.representativeSelected = this.profileData.representative;
      if (this.representativeSelected) {
        this.representativeQuery = this.representativeSelected.name;
      }

      // store.dispatch('app-placement/fetchProfile', {id: this.profileDataInfo.id})
      //     .then(response => {
      //
      //       this.profileDataLocal = respoinse;
      //
      //       if (this.profileData.attributes) {
      //         this.attributes = this.profileData.attributes;
      //       }
      //
      //     });
    },
  },
  mounted() {
    this.loadProfile();

    const { course } = this.$route.query;

    if (course) {
      this.courseId = course;

      if (!this.profileDataLocal.courses || !this.profileDataLocal.courses[course]) {
        this.profileDataLocal.courses = {};
        this.profileDataLocal.courses[course] = {
          examVoucher: 'no',
          examTaken: 'no',
          examPassed: 'no',
          labCompleted: 'no',
          status: 'pending',
        };
      }

      this.enableCourseAttributes = true;
    }
  },
  setup(props) {
    const profileDataInfo = ref(props.profileData);

    const PROFILE_APP_STORE_MODULE_NAME = 'app-roster';
    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, rosterStoreModule);
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });

    const representativeQuery = ref('');
    const representativeSuggestions = ref([]);
    const representativeSelected = ref({});
    const representativeSectionConfigs = {
      users: {
        limit: 10,
        label: 'Users',
        onSelected: (selected) => {
          representativeSelected.value = selected.item;
        },
      },
    };

    const representativeInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for users',
      class: 'form-control',
      name: 'ajax',
    };

    const {
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
      paymentStatusOptions,
      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
      courseStatusOptions,
      examVoucherOptions,
      yesNoOptions,
      locationOptions,
    } = useProfilesList();

    const pathwayQuery = ref('');
    const pathwaySuggestions = ref([]);
    const pathwaySelected = ref(undefined);
    const pathwaySectionConfigs = {
      pathways: {
        limit: 10,
        label: 'Pathways',
        onSelected: (selected) => {
          pathwaySelected.value = selected.item;
          profileData.value.pathway = selected.item;
        },
      },
    };

    const pathwayInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for pathways',
      class: 'form-control',
      name: 'ajax',
    };

    const subjectQuery = ref('');
    const subjectSuggestions = ref([]);
    const subjectSelected = ref(undefined);
    const subjectSectionConfigs = {
      subjects: {
        limit: 10,
        label: 'Subjects',
        onSelected: (selected) => {
          subjectSelected.value = selected.item;
          profileData.value.subject = selected.item;
        },
      },
    };

    const subjectInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for subjects',
      class: 'form-control',
      name: 'ajax',
    };

    return {
      representativeQuery,
      representativeSuggestions,
      representativeSelected,
      representativeSectionConfigs,
      representativeInputProps,

      profileDataInfo,
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
      paymentStatusOptions,
      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
      examVoucherOptions,
      yesNoOptions,
      courseStatusOptions,
      locationOptions,

      //
      pathwayQuery,
      pathwaySuggestions,
      pathwaySectionConfigs,
      pathwayInputProps,
      pathwaySelected,
      subjectQuery,
      subjectSuggestions,
      subjectSectionConfigs,
      subjectInputProps,
      subjectSelected,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
